import React, { FC, useState } from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"

import TopBanner from "gatsby-theme-gaviscon/src/components/TopBanner"
import Layout from "gatsby-theme-gaviscon/src/components/Layout"
import SubNavigationALP from "gatsby-theme-gaviscon/src/components/SubNavigationALP"
import ArticleCardsList from "gatsby-theme-gaviscon/src/components/ArticleCardsList"
import FeaturedArticle from "gatsby-theme-gaviscon/src/components/FeaturedArticle"

import { getSettingsPropsByStructure } from "gatsby-theme-gaviscon/src/utils/parseHelpers"
import BreadCrumbs from "gatsby-theme-gaviscon/src/components/common/BreadCrumbs"
import { IPropsArticleListingPage } from "gatsby-theme-gaviscon/src/templates/ArticleListingPage/model"
import "gatsby-theme-gaviscon/src/templates/ArticleListingPage/ArticleListingPage.scss"

const ArticleListingPage: FC<IPropsArticleListingPage> = ({
  data: {
    articles,
    page: {
      nodes: [
        {
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoExternalHreflangs,
          openGraphImageUrl,
          topBanner,
          submenu,
          ariaLabel,
          featuredArticle,
          submenuTitle,
          limit,
          limitMobile,
          showBreadcrumbs,
        },
      ],
    },
    header,
    footer,
    siteSettings,
    commonSettings,
  },
  pageContext: { breadCrumbs },
}) => {
  const [highlightedArticle] = useState<UmbracoTypes.IRelatedArticle | null>(
    () => {
      const newHighlightedArticle: UmbracoTypes.IRelatedArticle | null = featuredArticle?.[0]
        ? articles.nodes.filter(
            article => article.link === featuredArticle[0].url
          )[0]
        : null
      return newHighlightedArticle
    }
  )
  const ALPSettings = getSettingsPropsByStructure(
    commonSettings,
    "Article Listing Page"
  )

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      className="article-listing-page-container"
      header={header}
      footer={footer}
    >
      <BreadCrumbs data={breadCrumbs} showBreadcrumbs={showBreadcrumbs} />
      {topBanner?.[0]?.properties ? (
        <TopBanner {...topBanner[0].properties} />
      ) : null}
      <div className="article-listing-page">
        <SubNavigationALP categories={submenu} submenuTitle={submenuTitle} />
        {highlightedArticle ? (
          <FeaturedArticle
            link={featuredArticle}
            ariaButton={ariaLabel}
            featuredArticles={{ nodes: [highlightedArticle] }}
            imageAlign={["left"]}
          />
        ) : null}
        <Container fluid>
          <ArticleCardsList
            limit={limit}
            limitMobile={limitMobile}
            btnText={ALPSettings?.btnText}
            ariaMore={ALPSettings?.ariaMore}
            articles={articles.nodes.filter(
              item => item.id !== highlightedArticle?.id
            )}
          />
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ArticleListingQueryAU(
    $articlesLinks: [String]
    $link: String = ""
    $lang: String
    $articleSortInput: UmbracoArticlesSortInput
  ) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
          btnText
          ariaMore
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    page: allUmbracoArticleListing(
      filter: { link: { eq: $link }, lang: { eq: $lang } }
    ) {
      nodes {
        id
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          key
          value
        }
        openGraphImageUrl
        link
        ariaLabel
        limit
        limitMobile
        showBreadcrumbs
        submenuTitle
        featuredArticle {
          url
          name
        }
        topBanner {
          ...FragmentTopBannerAU
        }
        submenu {
          name
          articleCategory
          id
          link {
            url
            name
          }
          linkAria
        }
      }
    }
    articles: allUmbracoArticles(
      filter: { link: { in: $articlesLinks } }
      sort: $articleSortInput
    ) {
      nodes {
        id
        link
        title
        shortTitle
        shortText
        label
        text
        alt
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentMiddleImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        tags {
          id
          name
          articleCategory
          link {
            name
          }
        }
      }
    }
  }
`

export default ArticleListingPage
