import React, { FC, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { useLocation } from '@reach/router';
import { parseBoolean } from 'utils/parseHelpers';
import useStickyObserver from 'hooks/useStikyObserver';

import SubNavigationALPTitle from './SubNavigationALPTitle';
import { ISubNavigationALPProps } from './model';
import './SubNavigationALP.scss';
import './SubNavigationALPOverride.scss';

const SubNavigationALP: FC<ISubNavigationALPProps> = ({ categories, submenuTitle }) => {
  const submenuRef = useRef<HTMLDivElement>(null);
  const [isSticky] = useStickyObserver(submenuRef);
  const subNavigationCssClassNames = classnames('sub-navigation-alp', {
    'sub-navigation-alp--is-sticky': isSticky,
  });

  const location = useLocation();

  useEffect(() => {
    const activeNav = submenuRef.current?.getElementsByClassName('active')[0];
    activeNav?.scrollIntoView({ block: 'nearest' });
  }, []);

  useEffect(() => {
    const elBanner = document.getElementsByClassName('gs-top-banner')[0];
    const scrollToSubMenu = setTimeout(() => {
      if (elBanner) {
        const { height } = elBanner.getBoundingClientRect();
        location.href.includes('#subnavigation-alp') && window.scrollTo(0, height);
      }
    });

    return () => {
      clearTimeout(scrollToSubMenu);
    };
  }, [submenuRef?.current]);

  return categories?.length ? (
    <div id="subnavigation-alp" ref={submenuRef} className={subNavigationCssClassNames}>
      <div className="sub-navigation-alp__bg">
        {submenuTitle ? <SubNavigationALPTitle title={submenuTitle} /> : null}
        <div className="sub-navigation-alp__container">
          <ul className="sub-navigation-alp__list">
            {categories.map(({ link, name, articleCategory, linkAria }) => (
              <li
                key={name}
                className={classnames('sub-navigation-alp__list-item', {
                  'sub-navigation-alp__list-item--first': !parseBoolean(articleCategory),
                })}
              >
                <Link
                  data-title={link[0].name}
                  aria-label={linkAria}
                  to={`${link[0].url}#subnavigation-alp`}
                  className={classnames({
                    active: location.pathname === link[0].url,
                  })}
                >
                  {link[0].name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ) : null;
};

export default React.memo(SubNavigationALP);
