import React, { FC, useState, useEffect, useCallback } from 'react';

import CardRelatedArticle from 'components/CardRelatedArticle';
import Button from 'components/common/Button';

import useScreenRecognition from 'hooks/useScreenRecognition';
import { IArticleCardsListProps } from './model';
import './ArticleCardsList.scss';
import './ArticleCardsListOverride.scss';

const DefaultLimit = process.env.GATSBY_ARTICLES_LIST_INITIAL_LIMIT;

const ArticleCardsList: FC<IArticleCardsListProps> = ({
  articles,
  btnText,
  ariaMore,
  limit,
  limitMobile,
  articleCardTitleTag,
  commonArticleCardTitleTag,
}) => {
  const { isNotMobile } = useScreenRecognition();
  const [toShow, setToShow] = useState<number>(Number(DefaultLimit) || 0);

  const stepLoad = isNotMobile ? limit : limitMobile;
  const loadMore = useCallback(() => setToShow(toShow + stepLoad), [toShow]);
  useEffect(() => {
    setToShow(stepLoad);
  }, [stepLoad]);

  return articles?.length ? (
    <>
      <div className="article-cards-list" data-testid="article-cards-list">
        {articles.slice(0, toShow).map((article, index) => {
          const id = `${article.title}_${index}`;

          return (
            <CardRelatedArticle
              key={id}
              articleData={{
                ...article,
                titleTag: articleCardTitleTag || commonArticleCardTitleTag,
              }}
            />
          );
        })}
      </div>
      {articles?.length > 0 && btnText && toShow < articles.length ? (
        <Button
          ariaLabel={ariaMore}
          clickHandler={loadMore}
          classes="article-cards-list__btn"
          variant="link"
        >
          {btnText}
        </Button>
      ) : null}
    </>
  ) : null;
};
export default ArticleCardsList;
